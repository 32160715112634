import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import {graphql, Link, navigate} from "gatsby";
import React from 'react';
import authHeader from "../utils/auth-header";
import toast, {Toaster} from "react-hot-toast";
import LoadingSpinner from "../components/loading-spinner";
import {useTranslation} from "gatsby-plugin-react-i18next";

const ForgotPassword = () => {

    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {



    }, [])


    const handleChangePassword = (e) => {

        e.preventDefault();

        try {


            setSubmitDisabled(true);
            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    email: email,

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/get-password-reset-link", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabled(false);
                    setLoading(false)

                    if(resultData.status === 'Error'){
                        toast.error(resultData.alert.text)
                    }
                    else{

                        toast.success(resultData.alert.text);


                    }




                }) // set data for the number of stars






        } catch (err) {
            console.log(err);
        }


    };


    return (
        <Layout>

            <Seo title={t('forgot_password')}/>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-login"><LoadingSpinner /></div> : null}


            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-5-tablet is-4-desktop is-6-widescreen">
                                <div className="box notification is-warning">{t('forgot_password_info_text')}</div>
                                <form onSubmit={handleChangePassword} className={'box'}>

                                    <div className="field">
                                        <label className="label">{t('email')}</label>
                                        <div className="control">
                                            <input required className="input" type="email" value={email}
                                                   onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="field is-grouped">
                                        <div className="control">
                                            <button disabled={submitDisabled} type="submit" className="button is-link">{t('send_forgot_password_link')}
                                            </button>
                                        </div>
                                    </div>

                                    <Link activeClassName="is-active" to="/">{t('back_to_homepage')}</Link>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </Layout>
    )
}

export default ForgotPassword

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
